@import "../../../../public/mixins/mixins.scss";

.doctorCardWrapper {
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px #baf4ff;
  padding: 20px 0px;
  margin: 0px 10px;
  @include flexContainer(column, unset, center);
  width: 300px;

  .doctorImgWrapper {
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
  }

  .doctorInfo {
    padding: 0px 10px;
    width: 100%;
    @include flexContainer(column, center, center, 6px);

    .name {
      @include font(14px, 600, #4a536a, 0.56px);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .designation {
      @include font(12px, 600, #007a5a, 0.48px);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .studiedAt {
      @include font(11px, 500, #666161, 0.44px);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .heading {
      @include font(12px, 500, #4a536a, 0.48px);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .subHeading {
        @include font(12px, 500, #666161, 0.48px);
      }
    }
  }

  .priceInfo {
    padding: 20px 10px 0;
    width: 100%;
    border-top: 1px dashed #e4e8ff;

    .priceHeading {
      @include font(11px, 500, #4a536a, 0.44px);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .freeConsultBtn {
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include buttonType1(12px 35px, 7px, 14px, 600, #006daf);
    }
  }
}
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
